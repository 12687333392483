import React from 'react';
import { PageProps } from 'gatsby';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import GlobalStyles from '../styles/GlobalStyles';
import { DataLayerWrapper } from '../components/DataLayerWrapper';
import { UiStateContextProvider } from '../context/UiStateContext';
import ModalProvider from '../components/modal/ModalProvider';
import { Header } from '../components/header';
import { Footer } from '../components/footer';
import { useGlobalSettings } from '../hooks/useGlobalSettings';
import { SiteWideNotification } from '../components/siteWideNotification/SiteWideNotification';

export const BaseLayout = ({
  location,
  data,
  children,
  serverData,
}: PageProps) => {
  const { siteWideNotification } = useGlobalSettings();

  return (
    <GoogleReCaptchaProvider
      reCaptchaKey="6LewoUghAAAAADxYO2Jc9GxTfwjStawe2WwaTSAY"
      scriptProps={{ defer: true }}
      useRecaptchaNet
    >
      <GlobalStyles />
      {siteWideNotification?.enabled && (
        <SiteWideNotification
          description={siteWideNotification?.description}
          url={siteWideNotification?.link?.url}
          urlText={siteWideNotification?.link?.linkName}
        />
      )}

      <DataLayerWrapper data={data} serverData={serverData} location={location}>
        <UiStateContextProvider>
          <ModalProvider>
            <Header />
            <main>{children}</main>
            <Footer />
          </ModalProvider>
        </UiStateContextProvider>
      </DataLayerWrapper>
    </GoogleReCaptchaProvider>
  );
};
