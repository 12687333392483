import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React, { forwardRef } from 'react';
import { fontWeight } from '../../styles/tokens/typography';
import { getResponsiveType } from '../../utils';
import { ArrowLeft, ArrowRight } from '../icon';
import { LinkTo } from '../linkTo/LinkTo';

export interface LinkLabelProps {
  label?: string;
  url?: string;
  a11yTitle?: string;
  selected?: boolean;
  disabled?: boolean;
  arrowRight?: boolean;
  arrowLeft?: boolean;
  iconRight?: React.ReactNode;
  iconLeft?: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  inverse?: boolean;
  underline?: boolean;
}

type StyledLinkLabelProps = Pick<
  LinkLabelProps,
  'disabled' | 'selected' | 'inversed'
>;

export const StyledLinkLabel = styled.button<StyledLinkLabelProps>`
  ${({ theme: { space, colors }, disabled, inverse, underline }) => css`
    display: inline-flex;
    justify-content: center;
    cursor: pointer;
    align-items: center;
    gap: ${space.xxSmall};
    ${getResponsiveType('bodyMedium')}
    color: ${inverse ? colors.neutralWhite : colors.brandOne100};
    line-height: ${space.small};
    position: relative;
    text-decoration: ${underline ? 'underline' : 'none'};

    &:hover,
    &.is-active {
      text-decoration-line: underline;
      text-decoration-thickness: 2px;
      text-decoration-color: ${colors.brandOne100};
      color: ${colors.brandOne100};
      /* text-underline-offset: ${space.xxSmall}; */
    }

    // Diabled

    ${disabled &&
    css`
      cursor: default;
      opacity: 0.2;
    `}
  `};
`;

export const LinkLabel = forwardRef<
  HTMLButtonElement,
  React.PropsWithChildren<LinkLabelProps>
>(
  (
    {
      children,
      a11yTitle,
      onClick,
      disabled = false,
      url,
      label,
      selected = false,
      iconLeft,
      iconRight,
      arrowRight,
      arrowLeft,
      inversed,
      underline,
      ...rest
    },
    ref
  ) => {
    if (!label && !children) {
      return null;
    }

    // Icons

    const IconLeft = iconLeft;
    const IconRight = iconRight;

    const linkExtraProps = url ? { to: url } : {};

    return (
      <StyledLinkLabel
        ref={ref}
        as={!url ? 'button' : LinkTo}
        onClick={onClick}
        aria-label={a11yTitle}
        disabled={disabled}
        selected={selected}
        aria-disabled={disabled}
        inversed={inversed}
        underline={underline}
        {...linkExtraProps}
        {...rest}
      >
        {arrowLeft && <ArrowLeft />}
        {iconLeft && !arrowLeft ? IconLeft : ''}
        {children || label}
        {iconRight && !arrowRight ? IconRight : ''}
        {arrowRight && <ArrowRight />}
      </StyledLinkLabel>
    );
  }
);

LinkLabel.displayName = 'LinkLabel';
export default LinkLabel;
