import { space } from './space';

const borderRadii = {
  xxSmall: space.xxxSmall,
  xSmall: space.xxSmall,
  small: space.xSmall,
  medium: space.small,
  large: space.medium,
} as const;

export default borderRadii;

export type ThemeBorderRadii = typeof borderRadii;
export type ThemeBorderRadiiKeys = keyof typeof borderRadii;
