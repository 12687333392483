import React, { createContext, useState } from 'react';

interface UiStateContextProps {
  searchOpen: boolean | undefined;
  setSearchOpen: React.Dispatch<React.SetStateAction<boolean | undefined>>;
}

export const UiStateContext = createContext({} as UiStateContextProps);
UiStateContext.displayName = 'UiStateContext';

export const UiStateContextProvider = ({ children }) => {
  const [searchOpen, setSearchOpen] = useState<boolean | undefined>(false);

  const contextValues: UiStateContextProps = {
    searchOpen,
    setSearchOpen,
  };

  return (
    <UiStateContext.Provider value={contextValues}>
      {children}
    </UiStateContext.Provider>
  );
};
