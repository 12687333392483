import React, { FC } from 'react';
import { Icon, IconProps } from '../Icon';

export const Logo2: FC<IconProps> = (props) => (
  <Icon {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 408 414" fill="none">
      <path
        opacity=".05"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M366.43 138.381l-78.129 78.091-60.125-60.095L78.13 306.348 0 228.258 150.046 78.287l-.196-.196L227.98 0l138.45 138.381zM227.87 299.625l-60.127-60.097-78.13 78.091L228.063 456l78.13-78.091-.194-.193L456 227.79l-78.13-78.09-150 149.925z"
        fill="#fff"
      />
    </svg>
  </Icon>
);
