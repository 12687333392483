import React, { FC } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Info } from '../icon';
import { Typography } from '../typography';
import { LinkTo } from '../linkTo/LinkTo';
import { createMarkup, getBackgroundColor } from '../../utils';

// Interface

export interface SiteWideNotificationProps {
  description?: string;
  url?: string;
  urlText?: string;
}

// Styles

export const StyledSiteWideNotification = styled(Typography)`
  ${({ theme: { space, colors } }) => css`
    display: flex;
    align-items: center;
    gap: ${space.xxSmall};
    justify-content: center;
    padding: ${space.xxSmall};

    ${getBackgroundColor('brandOne100')}
  `};
`;

// JSX

export const SiteWideNotification = ({
  description,
  url,
  urlText,
  ...rest
}: SiteWideNotificationProps) => {
  if (!description) {
    return null;
  }

  return (
    <StyledSiteWideNotification component="div" variant="bodyMedium" {...rest}>
      <Info /> {createMarkup(description)}{' '}
      {url && <LinkTo to={url}>{urlText}</LinkTo>}
    </StyledSiteWideNotification>
  );
};

export default SiteWideNotification;
