import React, { FC } from 'react';
import { Link } from 'gatsby';

interface LinkToProps {
  to: string;
  onClick?: any;
  className?: string;
  state?: any;
  id?: string;
  label?: string;
  title?: string;
  target?: string;
}

export const LinkTo: FC<React.PropsWithChildren<LinkToProps>> = ({
  to,
  children,
  label,
  onClick,
  state,
  title,
  target,
  id,
  ...rest
}) => {
  const domain = process.env.GATSBY_FRONTEND_URL;

  let link = to;
  let isExternal = to?.trim().startsWith('http') || target === '_blank';
  const isAnchor = to?.trim().startsWith('#');
  const isMail = to?.trim().startsWith('mailto');
  const isTel = to?.trim().startsWith('tel');

  if (to && isExternal && domain && link?.includes(domain)) {
    [, link] = to.split(domain);
    isExternal = false;
  }

  return isExternal || isAnchor || isMail || isTel ? (
    // eslint-disable-next-line react/jsx-no-target-blank
    <a
      href={link}
      target={isExternal ? '_blank' : ''}
      rel={isExternal ? 'noopener' : ''}
      onClick={onClick}
      id={id}
      title={title}
      {...rest}
    >
      {label || children}
    </a>
  ) : (
    <Link
      to={link}
      id={id}
      activeClassName="is-active"
      onClick={onClick}
      title={title}
      state={state}
      {...rest}
    >
      {label || children}
    </Link>
  );
};

export default LinkTo;
