import React, { FC } from 'react';
import { Icon, IconProps } from '../Icon';

export const ChevronRight: FC<IconProps> = (props) => (
  <Icon {...props}>
    <svg viewBox="0 0 24 24">
      <path
        fill="currentColor"
        d="M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12zm0-21.75c5.385 0 9.75 4.365 9.75 9.75s-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12 6.615 2.25 12 2.25z"
      />
      <path
        fill="currentColor"
        d="m8.314 16.564 2.121 2.121 6.686-6.686-6.686-6.686-2.121 2.121 4.564 4.564z"
      />
    </svg>
  </Icon>
);
