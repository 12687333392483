import React, { FC } from 'react';
import { Icon, IconProps } from '../Icon';

export const Twitter: FC<IconProps> = (props) => (
  <Icon {...props}>
    <rect width="32" height="32" fill="#fff" rx="16" />
    <g clipPath="url(#a)">
      <path
        fill="currentColor"
        d="M17.333 14.934 22.545 9h-1.234l-4.528 5.15L13.17 9H9l5.465 7.79L9 23.012h1.236l4.778-5.441 3.816 5.441H23m-12.32-13.1h1.897l8.733 12.232h-1.897"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M9 9h14v14H9z" />
      </clipPath>
    </defs>
  </Icon>
);
