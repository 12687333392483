import { css } from '@emotion/react';
import { ThemeButtonKeys } from '../styles/tokens/buttons';
import { fontWeight } from '../styles/tokens/typography';
import { buttons, colors } from '../styles/tokens';

export const getButtonStyles = (variant: ThemeButtonKeys, selected = false) => {
  if (!variant) {
    return;
  }

  if (!buttons[variant]) {
    console.warn(`*** ${variant} NOT FOUND! ***`);
    return;
  }

  const buttonVariant = buttons[variant];

  return css`
    background-color: ${colors[buttons[variant].background]};
    border-color: ${colors[buttonVariant.borderColor]};
    font-weight: ${fontWeight.bold};
    color: ${colors[buttons[variant].color]};

    &:hover {
      background-color: ${colors[buttonVariant.hover.background]};
      border-color: ${colors[buttonVariant.hover.borderColor]};
      ${buttonVariant.hover?.color &&
      `color: ${colors[buttonVariant.hover.color]}`};
      text-decoration: ${buttonVariant.hover.underline ? `underline ` : `none`};
    }

    ${selected === true &&
    Object.prototype.hasOwnProperty.call(buttonVariant, 'selected') &&
    css`
      ${buttonVariant.selected?.background &&
      `background-color: ${colors[buttonVariant.selected.background]}`};
      ${buttonVariant.selected?.borderColor &&
      `border-color: ${colors[buttonVariant.selected.borderColor]}`};
      ${buttonVariant.selected?.color &&
      `color: ${colors[buttonVariant.selected.color]}`};

      &:hover {
        cursor: default;
        ${buttonVariant.selected?.background &&
        `background-color: ${colors[buttonVariant.selected.background]}`};
        ${buttonVariant.selected?.borderColor &&
        `border-color: ${colors[buttonVariant.selected.borderColor]}`};
        ${buttonVariant.selected?.color &&
        `color: ${colors[buttonVariant.selected.color]}`};
      }
    `}
  `;
};
