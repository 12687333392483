import React, { forwardRef } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { BaseFormProps, StyledBase } from '../Base/Base';
import { FormField, FormFieldProps } from '../FormField/FormField';

export interface TextareaProps extends BaseFormProps, FormFieldProps {
  rows?: number;
}
const BaseTextArea = StyledBase.withComponent('textarea');

const StyledTextArea = styled(BaseTextArea)`
  ${({ theme: { borderRadii } }) => css`
    border-radius: ${borderRadii.xSmall};
  `};
`;

export const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(
  (
    {
      label,
      id,
      rows,
      invalid,
      defaultValue,
      placeholder,
      required,
      errorMessage,
      hideLabel,
      disabled,
      hintText,
      ...rest
    },
    ref
  ) => (
    <FormField
      fieldAs="div"
      label={label}
      htmlFor={id}
      invalid={invalid}
      errorMessage={errorMessage}
      hideLabel={hideLabel}
      hintText={hintText}
      disabled={disabled}
      {...rest}
    >
      <StyledTextArea
        rows={rows}
        id={id}
        name={id}
        invalid={invalid}
        ref={ref}
        placeholder={placeholder}
        defaultValue={defaultValue}
        disabled={disabled}
        required={required}
        aria-describedby={hintText && `${id}-hint`}
      />
    </FormField>
  )
);
Textarea.displayName = 'Textarea';
