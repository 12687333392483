const layers = {
  heroBackgroundImage: -1,
  base: 0,
  heroBody: 1,
  headerSearch: 2,
  navigation: 10,
  navigationTitle: 11,
  navShowHideButton: 12,
  modal: 100,
} as const;

export default layers;

export type ThemeLayers = typeof layers;
