import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React, { forwardRef, RefObject } from 'react';
import { Typography } from '../../typography';

export interface OptionBaseProps {
  label?: string;
  id: string;
  checked?: boolean;
  defaultChecked?: boolean;
  onChange?: any;
  value?: string;
  disabled?: boolean;
  required?: boolean;
  invalid?: boolean;
  type?: 'checkbox' | 'radio';
  name?: string;
}

type StyledOptionBaseProps = Pick<OptionBaseProps, 'invalid' | 'disabled'>;

const StyledOptionBase = styled.div<StyledOptionBaseProps>`
  ${({ theme: { space, colors }, invalid, disabled }) => css`
    display: flex;
    gap: ${space.xSmall};

    + div {
      margin-top: ${space.xSmall};
    }

    label,
    input {
      cursor: pointer;
    }

    label {
      user-select: none;
      display: flex;
      align-items: center;

      input {
        position: absolute;
        opacity: 0;

        + span {
          display: inline-block;
          width: ${space.small};
          height: ${space.small};
          border: 2px solid ${colors.neutral100};
          position: relative;
          margin-right: ${space.xSmall};
          flex-shrink: 0;

          &:after {
            content: '';
          }
        }

        &:checked {
          + span {
            background-color: ${colors.neutral100};

            &:after {
              content: '';
              position: absolute;
              top: 4px;
              left: 4px;
              display: block;
              width: 12px;
              height: 12px;
              border-width: 6px;
            }
          }
        }

        &:focus-visible {
          + span {
            box-shadow: 0px 0px 0px 4px rgba(254, 225, 142, 1);
          }
        }

        &[type='checkbox'] {
          + span {
            border-radius: 2px;
          }

          &:checked {
            + span {
              background-color: ${colors.neutral100};

              &:after {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none'%3E%3Cpath fill='%23fff' d='M10.888 0c-.35.007-.69.182-.895.498L4.698 8.672 1.91 5.502A1.092 1.092 0 0 0 .27 6.946l3.74 4.25a1.093 1.093 0 0 0 1.734-.128l6.08-9.383A1.091 1.091 0 0 0 10.888 0Z'/%3E%3C/svg%3E");
                background-repeat: no-repeat;
                background-size: 12px;
              }
            }
          }
        }

        &[type='radio'] {
          + span {
            border-radius: 50%;
          }
          &:checked {
            + span {
              &:after {
                border-radius: 50%;
                border: 6px solid #fff;
                transition: border-width 0.3s ease;
              }
            }
          }
        }
      }
    }

    // Invalid state

    ${invalid &&
    css`
      accent-color: ${colors.systemDangerAccent};

      label {
        input {
          + span {
            border-color: ${colors.systemDanger};
            background-color: ${colors.systemDangerAccent};
          }
        }
      }
    `}

    // Disabled

     ${disabled &&
    css`
      opacity: 0.6;
      cursor: not-allowed;
    `}
  `};
`;

export const OptionBase = forwardRef<
  HTMLInputElement,
  React.PropsWithChildren<OptionBaseProps>
>(
  (
    {
      label,
      id,
      checked,
      defaultChecked,
      disabled,
      value,
      onChange,
      name,
      invalid,
      type = 'checkbox',
      ...rest
    },
    ref
  ) => (
    <StyledOptionBase invalid={invalid} disabled={disabled} {...rest}>
      <Typography as="label" htmlFor={id} variant="body">
        <input
          type={type}
          id={id}
          name={name || id}
          checked={checked}
          disabled={disabled}
          value={value}
          ref={ref as RefObject<HTMLInputElement>}
          onChange={onChange}
          defaultChecked={defaultChecked}
        />
        <span />
        {label || ''}
      </Typography>
    </StyledOptionBase>
  )
);

OptionBase.displayName = 'OptionBase';
