import React from 'react';
import { Script, ScriptStrategy } from 'gatsby';
import { ThemeProvider } from '@emotion/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { BaseLayout } from './src/layouts/Base';
import theme from './src/styles/tokens';
import { PageProvider } from './src/context/pageContext';
import { ScriptsBefore } from './src/components/ScriptsBefore';

const queryClient = new QueryClient();

export const wrapPageElement = ({ element, props }) => {
  const pageID = props?.data?.page?.remoteId;
  return (
    <QueryClientProvider client={queryClient}>
      <PageProvider pageID={pageID}>
        <BaseLayout {...props}>{element}</BaseLayout>
      </PageProvider>
    </QueryClientProvider>
  );
};

export const wrapRootElement = ({ element }) => (
  <ThemeProvider theme={theme}>
    <ScriptsBefore />
    {element}
  </ThemeProvider>
);

export const onRenderBody = ({ setHeadComponents, setHtmlAttributes }) => {
  setHeadComponents([
    // <Script
    //   strategy={ScriptStrategy.postHydrate}
    //   src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
    //   type="text/javascript"
    //   charSet="UTF-8"
    //   data-domain-script="19ed7cd6-d9ed-4dfa-af96-ee77920e0cad"
    // />,
    // <Script strategy={ScriptStrategy.postHydrate}>
    //   {`
    //   function OptanonWrapper() {}
    // `}
    // </Script>,
  ]);
  setHtmlAttributes({ lang: 'en-GB' });
};
