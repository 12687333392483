import React, { forwardRef, useId } from 'react';
import { OptionBase, OptionBaseProps } from '../OptionBase/OptionBase';

export type CheckboxProps = OptionBaseProps;

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  (
    {
      label,
      id,
      checked,
      defaultChecked,
      disabled,
      value,
      onChange,
      name,
      invalid,
      required,
      ...rest
    },
    ref
  ) => {
    const checkBoxId = useId();

    return (
      <OptionBase
        type="checkbox"
        label={label}
        id={checkBoxId}
        checked={checked}
        defaultChecked={defaultChecked}
        disabled={disabled}
        value={value}
        onChange={onChange}
        name={name}
        required={required}
        invalid={invalid}
        ref={ref}
        {...rest}
      />
    );
  }
);

Checkbox.displayName = 'Checkbox';
