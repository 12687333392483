import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import graphQLClient from '../client';
import { SUBMIT_UMBRACO_FORM_MUTATION } from '../graphql/mutations/submit-umbraco-form';

export interface FormField {
  field: string;
  value: string | boolean;
}

export interface UmbracoFormData {
  formId: string;
  umbracoPageId: string;
  fields: FormField[];
}

function useSubmitFormMutation(): UseMutationResult<
  void,
  Error,
  UmbracoFormData
> {
  return useMutation<void, Error, UmbracoFormData>((formData) => {
    const fieldsString = formData.fields
      .map(
        (field) => `
    {
      field: "${field.field}",
      value: "${field.value}"
    }
  `
      )
      .join(',');

    const SUBMIT_UMBRACO_FORM_MUTATION = gql`
      mutation submit {
        form {
          submit(
            formId: "${formData.formId.toString()}"
            umbracoPageId: "${formData.umbracoPageId.toString()}"
            fields: [${fieldsString}]
          )
        }
      }
    `;

    return graphQLClient.request(SUBMIT_UMBRACO_FORM_MUTATION);
  });
}

export default useSubmitFormMutation;
