import React, { useEffect } from 'react';

export const TdiGame: React.FC = () => {
  useEffect(() => {
    // Create a script element
    const script = document.createElement('script');
    script.src = `https://tradesman.crescat.digital/embedder.js?ver=${Date.now()}`;

    // Find the element to insert the script after
    const parentElement = document.getElementById('tdi-game');

    // Insert the script element after the target element
    if (parentElement) {
      parentElement.parentNode?.insertBefore(script, parentElement.nextSibling);
    }

    // Clean up the script element when the component unmounts
    return () => {
      if (parentElement) {
        parentElement.parentNode?.removeChild(script);
      }
    };
  }, []);

  return <div id="tdi-game" />;
};
