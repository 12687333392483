import React, { FC } from 'react';
import { Icon, IconProps } from '../Icon';

export const Close: FC<IconProps> = (props) => (
  <Icon {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path
        fill="currentColor"
        d="M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S18.627 0 12 0zm0 21.75c-5.385 0-9.75-4.365-9.75-9.75S6.615 2.25 12 2.25s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75z"
      />
      <path
        fill="currentColor"
        d="M15.75 6 12 9.75 8.25 6 6 8.25 9.75 12 6 15.75 8.25 18 12 14.25 15.75 18 18 15.75 14.25 12 18 8.25z"
      />
    </svg>
  </Icon>
);
