import React, { FC } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

// Interface

export interface DividerProps {
  inverse?: boolean;
}

// Styles

const StyledDivider = styled.hr<DividerProps>`
  ${({ theme: { colors }, inverse }) => css`
    border-width: 0px 0px 1px;
    border-style: solid;
    border-color: ${inverse ? colors.neutralWhite : colors.neutralMidGrey};
  `};
`;

// JSX

export const Divider: FC<DividerProps> = ({ inverse, ...rest }) => (
  <StyledDivider inverse={inverse} {...rest} />
);

export default Divider;
