import React, { FC } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

export interface SpacerProps {
  spacing?: 'xSmall' | 'small' | 'medium' | 'large';
  responsive?: boolean;
  as?: string | React.ComponentType<any>;
}

const SpacerEl = styled.div<SpacerProps>`
  ${({ theme: { space, media }, spacing, responsive }) => {
    let childSpacing;
    let childSpacingDesktop;

    switch (spacing) {
      case 'xSmall':
        childSpacing = space.xxSmall;
        childSpacingDesktop = space.xSmall;
        break;
      case 'small':
        childSpacing = space.xSmall;
        childSpacingDesktop = space.medium;
        break;
      case 'medium':
        childSpacing = space.medium;
        childSpacingDesktop = space.large;
        break;
      case 'large':
        childSpacing = space.large;
        childSpacingDesktop = space.xLarge;
        break;
      default:
    }

    return css`
      > * + * {
        margin-top: ${childSpacing};
      }

      // Responsive (adjust spacing for desktop)

      ${responsive &&
      css`
        @media (min-width: ${media.medium}) {
          > * + * {
            margin-top: ${childSpacingDesktop};
          }
        }
      `}
    `;
  }}
`;

export const Spacer: FC<React.PropsWithChildren<SpacerProps>> = ({
  children,
  spacing = 'medium',
  as,
  responsive,
  ...rest
}) => (
  <SpacerEl spacing={spacing} as={as} responsive={responsive} {...rest}>
    {children}
  </SpacerEl>
);

export default Spacer;
