import React, { FC } from 'react';
import { Icon, IconProps } from '../Icon';

export const ArrowLeft: FC<IconProps> = (props) => (
  <Icon {...props}>
    <path
      fill="currentColor"
      d="M25.88 16a1 1 0 0 0-1-1H9.482l2.115-2.184a.999.999 0 0 0-.024-1.415 1 1 0 0 0-1.414.023l-3.757 3.88a1 1 0 0 0 0 1.391l3.757 3.881a1 1 0 1 0 1.437-1.391L9.482 17h15.397a1 1 0 0 0 1-1Z"
    />
  </Icon>
);
