import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';

// Styles

const StyledLogo = styled.div`
  ${({ theme: { media } }) => css`
    @media (min-width: ${media.medium}) {
      * {
        width: 220px;
      }
    }
  `};
`;

// JSX

export const Logo = () => (
  <StyledLogo>
    <StaticImage src="./logo-tdi-kelliher.png" alt="logo" />
  </StyledLogo>
);

export default Logo;
