import React, { FC, useContext, useEffect, useState } from 'react';
import { useDataLayerPush } from '../hooks/useDataLayerPush';

export interface DataLayerWrapperProps {
  children: any;
  data: any;
  serverData: any;
  location: any;
}

export const DataLayerWrapper: FC<DataLayerWrapperProps> = ({
  children,
  data,
  serverData,
  location,
}) => {
  const { dataLayerPush } = useDataLayerPush();

  // Handle route change
  useEffect(() => {
    const pageData = {
      event: 'gatsby-route-change',
    };

    dataLayerPush({
      ...pageData,
    });
  }, [data]);
  return children;
};
