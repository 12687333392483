import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React, { forwardRef, useEffect, useState } from 'react';
import { space } from '../../../styles/tokens';
import { createMarkup, getResponsiveType } from '../../../utils';
import { Typography } from '../../typography/Typography';
import { BaseFormProps, StyledBase } from '../Base/Base';
import { FormField, FormFieldProps } from '../FormField/FormField';

export interface ButtonSelectProps extends BaseFormProps, FormFieldProps {
  options: ButtonSelectOptions[];
  onChange: (...event: any[]) => void;
}

export interface ButtonSelectOptions {
  label: string;
  description?: string;
  value: string | boolean;
}

// Styles

const StyledSelection = styled.div`
  ${({ theme: { space, media } }) => css`
    list-style: none;
    display: flex;
    gap: 24px;

    button {
      flex-grow: 1;
    }

    span {
      display: block;
    }
  `}
`;

interface StyledSelectButtonProps {
  selected?: boolean;
}

export const StyledSelectButton = styled(StyledBase)<StyledSelectButtonProps>`
  ${({ theme: { colors }, selected }) => css`
    flex-grow: 1;
    justify-content: center;
    align-items: center;
    ${getResponsiveType('bodyMediumBold')};

    span {
      display: block;
      margin-top: ${space.xxSmall};
    }

    ${selected &&
    css`
      background: ${colors.brandOne100};
      border-color: ${colors.brandOne100};
      color: '#fff';
      cursor: default;
    `};
  `}
`;

// JSX

export const ButtonSelect = forwardRef<HTMLSelectElement, ButtonSelectProps>(
  (
    {
      label,
      id,
      invalid,

      defaultValue,
      placeholder,
      required,
      errorMessage,
      hideLabel,
      disabled,
      hintText,
      optional,
      options,
      onChange,
      ...rest
    },
    ref
  ) => {
    const [inputValue, setInputValue] = useState<
      string | number | boolean | undefined
    >();

    useEffect(() => {
      setInputValue(defaultValue);
    }, [defaultValue]);

    const handleOnChange = (newValue: string | number | boolean) => {
      setInputValue(newValue);
      if (onChange) {
        onChange(newValue);
      }
    };

    return (
      <FormField
        fieldAs="div"
        label={label}
        htmlFor={id}
        invalid={invalid}
        errorMessage={errorMessage}
        hideLabel={hideLabel}
        hintText={hintText}
        disabled={disabled}
        optional={optional}
        {...rest}
      >
        <StyledSelection role="radiogroup" id={id}>
          {options?.length &&
            options.map((option, index) => (
              <StyledSelectButton
                key={option.value as string}
                as="button"
                type="button"
                onClick={() => handleOnChange(option.value)}
                selected={inputValue === option.value}
                role="radio"
                aria-checked={inputValue === option.value}
                invalid={invalid}
                id={`${id}-${index}`}
              >
                <Typography component="strong" variant="bodyMediumBold">
                  {createMarkup(option.label)}
                </Typography>
                {option.description && (
                  <Typography component="span" variant="bodySmall">
                    {option.description}
                  </Typography>
                )}
              </StyledSelectButton>
            ))}
        </StyledSelection>
      </FormField>
    );
  }
);

ButtonSelect.displayName = 'ButtonSelect';
