import React, { FC } from 'react';
import { Icon, IconProps } from '../Icon';

export const Instagram: FC<IconProps> = (props) => (
  <Icon {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="-250 -250 1024 1024">
      <path
        fill="currentColor"
        d="M370.7 0H141.3C63.4 0 0 63.4 0 141.3v229.4C0 448.6 63.4 512 141.3 512h229.4c77.9 0 141.3-63.4 141.3-141.3V141.3C512 63.4 448.6 0 370.7 0zm95.9 370.7c0 52.9-43 95.9-95.9 95.9H141.3c-52.9 0-95.9-43-95.9-95.9V141.3c0-52.9 43-95.9 95.9-95.9h229.4c52.9 0 95.9 43 95.9 95.9v229.4z"
      />
      <path
        fill="currentColor"
        d="M256 124.1c-72.7 0-131.9 59.2-131.9 131.9S183.3 387.9 256 387.9 387.9 328.7 387.9 256 328.7 124.1 256 124.1zm0 218.4c-47.7 0-86.5-38.8-86.5-86.5s38.8-86.5 86.5-86.5 86.5 38.8 86.5 86.5-38.8 86.5-86.5 86.5zM393.5 85.6c-8.8 0-17.4 3.5-23.5 9.8-6.2 6.2-9.8 14.8-9.8 23.6s3.6 17.4 9.8 23.6c6.2 6.2 14.8 9.8 23.5 9.8 8.8 0 17.4-3.6 23.6-9.8 6.2-6.2 9.8-14.8 9.8-23.6s-3.5-17.4-9.8-23.6c-6.3-6.3-14.9-9.8-23.6-9.8z"
      />
    </svg>
  </Icon>
);
