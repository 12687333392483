import React, { FC } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { createMarkup, getResponsiveType } from '../../utils';
import { Spacer } from '../spacer';

// Interface

export interface HtmlContentProps {
  children?: any;
}

// Styles

const StyledHtmlContent = styled(Spacer)`
  ${({ theme: { space, colors, global, borders } }) => css`
    margin-left: auto;
    margin-right: auto;
    ol,
    ul {
      padding-left: ${space.small};
      list-style: reset;
      margin-bottom: ${space.xSmall};

      li + li {
        margin-top: ${space.xxSmall};
      }
    }

    // Links

    a {
      color: ${colors.brandOne100};
    }

    // List styles

    ul {
      list-style: none;
      padding-left: ${space.xxSmall};
    }

    li::before {
      content: '• ';
      color: ${colors.brandOne100};
      margin-right: ${space.xxSmall};
    }
    // Typography

    h1,
    h2,
    h3 {
      margin-bottom: 1rem;
      margin-top: 1em;
    }

    h1 {
      ${getResponsiveType('display')}
    }

    h2 {
      ${getResponsiveType('headingMedium')}
    }

    h3 {
      font-size: 26px;
      line-height: 32px;
      font-weight: 500;
    }

    h4 {
      ${getResponsiveType('headingSmall')}
    }

    h5,
    h6 {
      ${getResponsiveType('bodyLarge')}
      font-weight: bold;
    }

    p {
      margin-bottom: 1rem;
    }

    > *:first-of-type {
      margin-top: 0;
    }

    > *:last-child {
      margin-bottom: 0;
    }
  `};
`;

// JSX

export const HtmlContent: FC<React.PropsWithChildren<HtmlContentProps>> = ({
  children,
  ...rest
}) => (
  <StyledHtmlContent {...rest} spacing="small">
    {typeof children === 'string' ? createMarkup(children) : children}
  </StyledHtmlContent>
);

export default HtmlContent;
