import { GraphQLClient } from 'graphql-request';

const graphqlUrl =
  process.env.GATSBY_MUTATION_URL ??
  'https://trade-direct-insurance.uksouth01.umbraco.io/graphql';
const graphQLClient = new GraphQLClient(graphqlUrl, {
  headers: {
    'GRAPHQL-ACCESS-TOKEN': `${process.env.GATSBY_MUTATION_AUTH_TOKEN}`,
  },
});

export default graphQLClient;
