import React, { FC } from 'react';
import { Icon, IconProps } from '../Icon';

export const Info: FC<IconProps> = (props) => (
  <Icon {...props}>
    <path
      fill="currentColor"
      d="M16 5C9.925 5 5 9.925 5 16s4.925 11 11 11 11-4.925 11-11S22.075 5 16 5Zm0 2a9 9 0 1 1 0 18 9 9 0 0 1 0-18Zm0 3.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3Zm0 4.5a1 1 0 0 0-1 1v5a1 1 0 0 0 2 0v-5a1 1 0 0 0-1-1Z"
    />
  </Icon>
);
