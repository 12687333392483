import { shade, tint } from 'polished';

export const brandColors = {
  brandOne: '#D12E26', // Primary
  brandTwo: '#DD6E2D', // Secondary
  brandThree: '#ffcb05', // Tertiary
} as const;

export const neutralColors = {
  neutral100: '#121111',
  neutralWhite: '#FFF',
  neutralLightGrey: '#eeedec',
  neutralMidGrey: '#dadada',
  neutralGrey: '#121111',
  neutralOpacity: 'rgba(0,0,0,.5)',
  neutralGold: brandColors.brandThree,
} as const;

export const systemColors = {
  systemFocus: '#FFE18F',
  systemInformation: '#9EB9D3',
  systemSuccess: '#89A19A',
  systemDanger: '#862D32',
  systemDangerAccent: '#F3EAEB',
} as const;

// Auto generated brand tones

export const brandToneColors = {
  brandOne120: shade(0.2, brandColors.brandOne),
  brandOne100: brandColors.brandOne,
  brandOne80: tint(0.2, brandColors.brandOne),
  brandOne50: tint(0.5, brandColors.brandOne),
  brandOne10: tint(0.9, brandColors.brandOne),
  brandTwo120: shade(0.2, brandColors.brandTwo),
  brandTwo100: brandColors.brandTwo,
  brandTwo80: tint(0.2, brandColors.brandTwo),
  brandTwo50: tint(0.5, brandColors.brandTwo),
  brandTwo10: tint(0.9, brandColors.brandTwo),
} as const;

// Main colours

export const colors = {
  transparent: 'transparent',
  ...neutralColors,
  ...systemColors,
  ...brandToneColors,
} as const;

export default colors;

export type ThemeColors = typeof colors;
export type ThemeColorsKeys = keyof typeof colors;
