export const space = {
  xxxSmall: '4px',
  xxSmall: '8px',
  xSmall: '16px',
  small: '24px',
  medium: '32px',
  large: '48px',
  xLarge: '64px',
  xxLarge: '96px',
  xxxLarge: '120px',
  xxxxLarge: '264px',
} as const;

export default space;
export type ThemeSpace = typeof space;
export type ThemeSpaceKeys = keyof typeof space;
