import React, { useState, createContext, useMemo } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { getBackgroundColor } from '../../utils';
import { Button } from '../button';
import { Close } from '../icon';

export const ModalContext = createContext({});

const ModalBackground = styled.div`
  ${({ theme: { layers } }) => css`
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    ${getBackgroundColor('neutralOpacity')}
    z-index: ${layers.modal};
  `};
`;

const ModalShell = styled.div`
  ${({ theme: { media, space } }) => css`
    width: 100vw;
    height: 100vh;
    ${getBackgroundColor('neutralWhite')}
    padding: ${space.medium};
    padding-top: ${space.large};
    position: relative;

    @media (min-width: ${media.medium}) {
      width: 50vw;
      max-width: 480px;
      height: auto;
    }
  `};
`;

const CloseModalButton = styled(Button)`
  ${({ theme: { space } }) => css`
    position: absolute;
    right: ${space.small};
    top: ${space.xSmall};
  `};
`;

const ModalProvider = ({ children }: { children: any }) => {
  const [modalContent, setModalContent] = useState(null);

  return (
    <ModalContext.Provider
      value={useMemo(
        () => ({
          hide: () => {
            document.body.style.overflow = '';
            setModalContent(null);
          },
          open: (content: any) => {
            setModalContent(content);
            document.body.style.overflow = 'hidden';
          },
        }),
        []
      )}
    >
      {modalContent ? (
        <ModalBackground
          onClick={() => {
            document.body.style.overflow = '';
            setModalContent(null);
          }}
        >
          <ModalShell
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <CloseModalButton
              variant="primaryInverse"
              iconRight={<Close size="small" />}
              a11yTitle="Close"
              onClick={() => {
                document.body.style.overflow = '';
                setModalContent(null);
              }}
              small
            />
            {modalContent}
          </ModalShell>
        </ModalBackground>
      ) : null}
      {children}
    </ModalContext.Provider>
  );
};

export default ModalProvider;
