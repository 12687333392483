import { ThemeBorderRadiiKeys } from './borderRadii';
import { ThemeColorsKeys } from './colors';
import { ThemeFontsKeys } from './typography';

interface ThemeButtonBase {
  color: ThemeColorsKeys;
  background: ThemeColorsKeys;
  borderColor: ThemeColorsKeys;
  borderRadius: ThemeBorderRadiiKeys;
  underline?: boolean;
}

interface ThemeButton extends ThemeButtonBase {
  type: ThemeFontsKeys;
  hover: ThemeButtonBase;
  selected?: ThemeButtonBase;
}

const buttons = {
  primary: {
    borderColor: 'brandOne100',
    background: 'brandOne100',
    color: 'neutralWhite',
    hover: {
      borderColor: 'brandOne120',
      background: 'brandOne120',
    },
    selected: {
      borderColor: 'brandTwo100',
      background: 'brandTwo100',
      color: 'neutralWhite',
    },
  },
  primaryInverse: {
    borderColor: 'transparent',
    background: 'transparent',
    color: 'brandOne100',
    hover: {
      borderColor: 'neutralWhite',
      background: 'brandOne100',
      color: 'neutralWhite',
    },
    selected: {
      borderColor: 'neutralWhite',
      background: 'brandOne100',
      color: 'neutralWhite',
    },
  },
  primaryInverseOutline: {
    borderColor: 'brandOne100',
    background: 'transparent',
    color: 'brandOne100',
    hover: {
      borderColor: 'neutralWhite',
      background: 'brandOne100',
      color: 'neutralWhite',
    },
    selected: {
      borderColor: 'neutralWhite',
      background: 'brandOne100',
      color: 'neutralWhite',
    },
  },
  secondary: {
    borderColor: 'brandTwo100',
    background: 'brandTwo100',
    color: 'neutralWhite',
    hover: {
      borderColor: 'brandTwo120',
      background: 'brandTwo120',
    },
    selected: {
      borderColor: 'brandOne100',
      background: 'brandOne100',
      color: 'brandTwo100',
    },
  },
  secondaryOutline: {
    borderColor: 'brandTwo100',
    background: 'transparen',
    color: 'brandTwo100',
    hover: {
      borderColor: 'brandTwo120',
      background: 'brandTwo120',
      color: 'neutralWhite',
    },
    selected: {
      borderColor: 'brandTwo100',
      background: 'brandTwo100',
      color: 'neutralWhite',
    },
  },
  secondaryInverse: {
    borderColor: 'transparent',
    background: 'transparent',
    color: 'brandTwo100',
    hover: {
      borderColor: 'neutralWhite',
      background: 'transparent',
      color: 'neutralWhite',
    },
    selected: {
      borderColor: 'brandTwo100',
      background: 'brandTwo100',
      color: 'neutralWhite',
    },
  },
  secondaryInverseOutline: {
    borderColor: 'neutralWhite',
    background: 'transparent',
    color: 'neutralWhite',
    hover: {
      borderColor: 'neutralWhite',
      background: 'neutralWhite',
      color: 'brandTwo100',
    },
    selected: {
      borderColor: 'neutralWhite',
      background: 'neutralWhite',
      color: 'brandTwo100',
    },
  },
  tertiary: {
    borderColor: 'brandTwo50',
    background: 'brandTwo50',
    color: 'brandTwo100',
    hover: {
      borderColor: 'neutralWhite',
      background: 'neutralWhite',
      color: 'brandTwo100',
    },
    selected: {
      borderColor: 'neutralWhite',
      background: 'neutralWhite',
      color: 'brandTwo100',
    },
  },
} as const;

export default buttons;

export type ThemeButtonKeys = keyof typeof buttons;
export type ThemeButtons = Record<ThemeButtonKeys, ThemeButton>;
