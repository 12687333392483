import React from 'react';

import parse, { HTMLReactParserOptions } from 'html-react-parser';
import { replaceSiteUrl } from './replaceSiteUrl';
import { TdiGame } from '../components/TdiGame';

const options: HTMLReactParserOptions = {
  replace: (domNode) => {
    if (
      domNode instanceof Object &&
      domNode.attribs &&
      domNode.attribs.id === 'tdi-game'
    ) {
      return <TdiGame />;
    }
  },
};
type markupArg = string | string[];
/**
 * Takes in HTML as input and returns parsed HTML output.
 *
 * @param {markupArg} html - The HTML to be parsed.
 * @returns {string|null} - The parsed HTML output or null if input is invalid.
 */
export const createMarkup = (html: markupArg) => {
  let checkHtml = html;
  if (!checkHtml) {
    return null;
  }
  if (Array.isArray(checkHtml)) {
    checkHtml = checkHtml.join('');
  }
  if (typeof checkHtml !== 'string') {
    return null;
  }
  const videoWrapperDiv = {
    start: '<div class="video-wrapper">',
    end: '</div>',
  };
  const iframeRegex = /<iframe.*?<\/iframe>/g;

  const output: string = checkHtml.replace(
    iframeRegex,
    (match) => videoWrapperDiv.start + match + videoWrapperDiv.end
  );

  return parse(replaceSiteUrl(output), options);
};
