import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { getBackgroundColor } from '../../../utils';
import { getOffsetSpace } from '../../../utils/getOffsetSpace';

export interface BaseFormProps {
  id: string;
  invalid?: boolean;
  required?: boolean;
  placeholder?: string;
  disabled?: boolean;
  defaultValue?: string;
}

interface StyledInputProps {
  invalid?: boolean;
}

export const StyledBase = styled.input<StyledInputProps>`
  ${({ theme: { borderRadii, colors, space, media }, invalid, as }) => {
    const spaceXxSmall = getOffsetSpace(space.xxSmall);
    const spaceXSmall = getOffsetSpace(space.xSmall);
    const spaceSmall = getOffsetSpace(space.small);
    const spaceMedium = getOffsetSpace(space.medium);

    // Size
    const paddingSmall = `${spaceXxSmall} ${spaceSmall}`;
    const paddingLarge = `${spaceXSmall} ${spaceSmall}`;

    return css`
      border: solid 1px ${colors.brandTwo100};
      ${getBackgroundColor('neutralWhite')}
      padding: ${paddingSmall};
      width: 100%;
      display: block;
      position: relative;
      transition: all 0.2s ease;
      border-radius: ${borderRadii.large};
      min-height: ${space.large};

      &::placeholder {
        color: ${colors.neutral100};
      }

      &:focus,
      &:hover {
        outline: none;
        border-color: ${colors.brandTwo100};
      }

      // If select input
      ${as === 'select' &&
      css`
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' class='Icon__StyledIcon-NDAcm ieTwjA'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath d='M0 0h32v32H0z'%3E%3C/path%3E%3Cpath fill='currentColor' d='M8.25 11h16.09c1.112 0 1.669 1.344.88 2.132l-8.04 8.047a1.253 1.253 0 0 1-1.77 0l-8.04-8.047C6.58 12.344 7.137 11 8.25 11Z'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-position: right 16px center;
        background-size: 16px 16px;
        appearance: none;
        padding-right: ${space.large};
      `}

      // Invalid state

    ${invalid &&
      css`
        border-color: ${colors.systemDanger};

        &:focus,
        &:hover {
          border-color: ${colors.systemDanger};
        }
      `}

         // Large screens

      @media (min-width: ${media.medium}) {
        padding: ${paddingLarge};
        min-height: ${space.xLarge};
      }
    `;
  }};
`;
