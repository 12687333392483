import React, { FC } from 'react';
import { Icon, IconProps } from '../Icon';

export const Tick: FC<IconProps> = (props) => (
  <Icon {...props}>
    <svg viewBox="-5 -5 20 20">
      <path
        fill="currentColor"
        d="M4.528 4.895L2.56 2.933 0 5.482 4.535 10l2.56-2.55-.006-.006L12 2.55 9.44 0 4.528 4.895z"
      />
    </svg>
  </Icon>
);
