const iconSizes = {
  small: '24 24',
  medium: '32 32',
  large: '64 64',
  xLarge: '128 128',
} as const;

export default iconSizes;
export type ThemeIconSizes = typeof iconSizes;
export type ThemeIconSizesKeys = keyof typeof iconSizes;
