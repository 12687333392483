import React, { FC } from 'react';
import { Icon, IconProps } from '../Icon';

export const ArrowRight: FC<IconProps> = (props) => (
  <Icon {...props}>
    <path
      fill="currentColor"
      d="M6.121 16a1 1 0 0 0 1 1h15.396l-2.115 2.185a.999.999 0 0 0 .024 1.414 1 1 0 0 0 1.414-.023l3.758-3.88a1 1 0 0 0 0-1.391l-3.758-3.88a1 1 0 1 0-1.437 1.39L22.52 15H7.12a1 1 0 0 0-1 1Z"
    />
  </Icon>
);
