import { css } from '@emotion/react';
import {
  ThemeFontsKeys,
  TypeStyles,
  TypeVariantsOptions,
} from '../styles/tokens/typography';
import { typography, media } from '../styles/tokens';

/**
 * Returns a CSS style object based on the variant passed in.
 * @param {ThemeFontsKeys} variant - The variant to generate the style for.
 * @returns {string} - The generated CSS style.
 */
export const getResponsiveType = (variant: ThemeFontsKeys) => {
  if (!variant) {
    return;
  }

  const generateTypeStyles = (version: TypeVariantsOptions) => {
    const versionStyles: TypeStyles = typography[variant]?.[version];

    if (!versionStyles) {
      return;
    }

    const styles = css`
      ${versionStyles.fontFamily
        ? `font-family: ${versionStyles.fontFamily};`
        : ''}
      ${versionStyles.fontWeight
        ? `font-weight: ${versionStyles.fontWeight};`
        : ''}
      ${versionStyles.fontSize ? `font-size: ${versionStyles.fontSize};` : ''}
      ${versionStyles.lineHeight
        ? `line-height: ${versionStyles.lineHeight};`
        : ''}
      ${versionStyles.textTransform
        ? `text-transform: ${versionStyles.textTransform};`
        : ''} 
      ${versionStyles.letterSpacing
        ? `letter-spacing: ${versionStyles.letterSpacing};`
        : ''} 


      ${versionStyles?.breakpoints && versionStyles?.breakpoints?.length > 0
        ? versionStyles.breakpoints.map((breakpoint) => {
            const { breakpointSize, fontSize, lineHeight, letterSpacing } =
              breakpoint;

            return css`
              @media (min-width: ${media[breakpointSize]}) {
                ${fontSize ? `font-size: ${fontSize};` : ''}
                ${lineHeight ? `line-height: ${lineHeight};` : ''}
          ${letterSpacing ? `letter-spacing: ${letterSpacing};` : ''}
              }
            `;
          })
        : ''};
    `;

    return styles;
  };

  const typeStyles = generateTypeStyles('default');

  return typeStyles;
};
