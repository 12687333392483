import React, { forwardRef, RefObject } from 'react';
import { BaseFormProps, StyledBase } from '../Base/Base';
import { FormField, FormFieldProps } from '../FormField/FormField';

interface SelectOptions {
  label: string;
  value: string | number;
}

export interface SelectProps extends BaseFormProps, FormFieldProps {
  options: SelectOptions[];
}

const StyledSelect = StyledBase.withComponent('select');
export const Select = forwardRef<
  HTMLSelectElement,
  React.PropsWithChildren<SelectProps>
>(
  (
    {
      children,
      options,
      label,
      id,
      invalid,
      defaultValue,
      placeholder,
      required,
      errorMessage,
      hideLabel,
      disabled,
      hintText,
      ...rest
    },
    ref
  ) => (
    <FormField
      fieldAs="div"
      label={label}
      htmlFor={id}
      invalid={invalid}
      errorMessage={errorMessage}
      hideLabel={hideLabel}
      hintText={hintText}
      disabled={disabled}
    >
      <StyledSelect
        as="select"
        id={id}
        name={id}
        invalid={invalid}
        ref={ref}
        defaultValue={defaultValue}
        disabled={disabled}
        required={required}
        aria-describedby={hintText && `${id}-hint`}
        {...rest}
      >
        {!children && (
          <>
            <option value="">{placeholder || 'Please select'}</option>
            {options.map((option, i) => (
              <option key={i} value={option.value}>
                {option.label}
              </option>
            ))}
          </>
        )}
        {children}
      </StyledSelect>
    </FormField>
  )
);

Select.displayName = 'Select';
