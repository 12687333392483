module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"/opt/build/repo/packages/theme/src/images/favicon-32x32.png","name":"Trade Direct Insurance","short_name":"Trade Direct","start_url":"/","background_color":"#d12e26","theme_color":"#d12e26","display":"standalone","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"e602d1e354ed3a57035e49f916e8ff74"},
    },{
      plugin: require('../../theme/gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
