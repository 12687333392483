const gradients = {
  netural100Vertical:
    'linear-gradient(360deg, #033137 24.18%, rgba(3, 49, 55, 0) 100%)',
  netural100Horizontal:
    'linear-gradient(90deg, #033137 0%, rgba(3, 49, 55, 0) 100%)',
  brandGreenRed:
    'linear-gradient(90deg,rgba(88, 36, 19, 1) 0%,rgba(98, 76, 27, 1) 31%,rgba(219, 80, 46, 1) 100%)',
  heroOverlay:
    'linear-gradient(90deg,rgba(0, 0, 0, 0.6) 60.61%,rgba(0, 0, 0, 0) 100%)',
} as const;

export default gradients;

export type ThemeGradients = typeof gradients;
export type ThemeGradientsKeys = keyof typeof gradients;
