import { graphql, useStaticQuery } from 'gatsby';

interface GlobalSettingProps {
  socialLinks: Queries.getGlobalSettingsQuery['socialLinks'];
  siteWideNotification: Queries.getGlobalSettingsQuery['siteWideNotification'];
  siteMeta: Queries.SiteSiteMetadata;
}
export const useGlobalSettings = (): GlobalSettingProps => {
  const {
    socialLinks,
    siteWideNotification,
    site: { siteMetadata },
  } = useStaticQuery(graphql`
    query getGlobalSettings {
      site {
        siteMetadata {
          siteUrl
          companyName
          shortName
          title
          googleVerification
          pinterestVerification
          metaTitleFallback
        }
      }
      socialLinks: umbracoSocialLinksType {
        facebook
        instagram
        twitter
        youTube
        pinterest
      }
      siteWideNotification: umbracoSitewideNotificationType {
        enabled
        description
        link {
          url
          target
          linkName
        }
      }
    }
  `);

  const returnValues: GlobalSettingProps = {
    socialLinks,
    siteWideNotification,
    siteMeta: siteMetadata,
  };

  return returnValues;
};
