import React, { forwardRef, RefObject } from 'react';
import { BaseFormProps, StyledBase } from '../Base/Base';
import { FormField, FormFieldProps } from '../FormField/FormField';

export interface InputProps extends BaseFormProps, FormFieldProps {
  type: 'text' | 'email' | 'password' | 'tel' | 'search';
}

export const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      label,
      id,
      type = 'text',
      invalid,
      defaultValue,
      placeholder,
      required,
      errorMessage,
      hideLabel,
      disabled,
      hintText,
      optional,
      ...rest
    },
    ref
  ) => (
    <FormField
      fieldAs="div"
      label={label}
      htmlFor={id}
      invalid={invalid}
      errorMessage={errorMessage}
      hideLabel={hideLabel}
      hintText={hintText}
      disabled={disabled}
      optional={optional}
      required={required}
      {...rest}
    >
      <StyledBase
        as="input"
        type={type}
        id={id}
        name={id}
        invalid={invalid}
        ref={ref as RefObject<HTMLInputElement>}
        placeholder={placeholder}
        defaultValue={defaultValue}
        disabled={disabled}
        required={required}
        aria-describedby={hintText && `${id}-hint`}
      />
    </FormField>
  )
);

Input.displayName = 'Input';
