import { ThemeMediaKeys } from './media';

const fontFamily = {
  fontRegular: '"Museo Sans W01","Helvetica Neue",Helvetica,sans-serif',
  fontBold: '"Museo Sans W01","Helvetica Neue",Helvetica,sans-serif',
};

export const fontWeight = {
  regular: 400,
  bold: 700,
};

export interface TypeStyles {
  fontFamily?: string;
  fontWeight?: number;
  fontSize?: string;
  lineHeight?: string;
  textTransform?: string;
  letterSpacing?: string;
  breakpoints?: [TypeBreakpoints];
}

interface TypeBreakpoints extends TypeStyles {
  breakpointSize: ThemeMediaKeys;
}
export interface TypeVariants {
  default: TypeStyles;
}
export type TypeVariantsOptions = keyof TypeVariants;

export type ThemeFonts = Record<string, TypeVariants>;

const type: ThemeFonts = {
  display: {
    default: {
      fontWeight: fontWeight.regular,
      fontFamily: fontFamily.fontRegular,
      fontSize: '36px',
      lineHeight: '48px',
    },
  },
  headingLarge: {
    default: {
      fontWeight: fontWeight.bold,
      fontFamily: fontFamily.fontRegular,
      fontSize: '36px',
      lineHeight: '48px',
    },
  },
  headingMedium: {
    default: {
      fontWeight: fontWeight.regular,
      fontFamily: fontFamily.fontRegular,
      fontSize: '32px',
      lineHeight: '38px',
    },
  },
  headingMediumBold: {
    default: {
      fontWeight: fontWeight.bold,
      fontFamily: fontFamily.fontBold,
      fontSize: '24px',
      lineHeight: '32px',
    },
  },
  headingMediumSmall: {
    default: {
      fontWeight: fontWeight.regular,
      fontFamily: fontFamily.fontRegular,
      fontSize: '24px',
      lineHeight: '28px',
    },
  },
  headingSmall: {
    default: {
      fontWeight: fontWeight.regular,
      fontFamily: fontFamily.fontRegular,
      fontSize: '16px',
      lineHeight: '22px',
    },
  },
  bodyLarge: {
    default: {
      fontWeight: fontWeight.regular,
      fontFamily: fontFamily.fontBold,
      fontSize: '18px',
      lineHeight: '24px',
    },
  },
  bodyMedium: {
    default: {
      fontWeight: fontWeight.regular,
      fontFamily: fontFamily.fontRegular,
      fontSize: '16px',
      lineHeight: '22px',
    },
  },
  bodyMediumBold: {
    default: {
      fontWeight: fontWeight.bold,
      fontFamily: fontFamily.fontRegular,
      fontSize: '16px',
      lineHeight: '22px',
    },
  },
  bodySmall: {
    default: {
      fontWeight: fontWeight.regular,
      fontFamily: fontFamily.fontRegular,
      fontSize: '14px',
      lineHeight: '18px',
    },
  },
  caps: {
    default: {
      fontWeight: fontWeight.bold,
      fontFamily: fontFamily.fontBold,
      fontSize: '16px',
      lineHeight: '16px',
      textTransform: 'uppercase',
      letterSpacing: '1px',
    },
  },
  quote: {
    default: {
      fontWeight: fontWeight.bold,
      fontFamily: fontFamily.fontRegular,
      fontSize: '1.2em',
      // fontSize: 'clamp(2.5rem, 2.05rem + 1.333vw, 3rem)', // 40px 48px
      // lineHeight: 'clamp(3rem, 2.55rem + 1.333vw, 3.5rem)', // 48px 56px
      // letterSpacing: '-1px',
    },
  },
};

export default type;

export type ThemeFontsKeys = keyof typeof type;
