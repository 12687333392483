import React from 'react';
import { Script, ScriptStrategy } from 'gatsby';

export const ScriptsBefore = () => {
  const GTM_ID = process.env.GATSBY_GTM_ID || 'GTM-WCK3FXN';
  return (
    <>
      {/* GTM */}
      <Script strategy={ScriptStrategy.postHydrate}>
        {`
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer', '${GTM_ID}');
      `}
      </Script>
      <noscript>
        <iframe
          src={`https://www.googletagmanager.com/ns.html?id=${GTM_ID}`}
          height="0"
          width="0"
          style={{ display: 'none', visibility: 'hidden' }}
          title="gtm-frame"
        />
      </noscript>
    </>
  );
};
