import React, { FC } from 'react';
import { Icon, IconProps } from '../Icon';

export const Account: FC<IconProps> = (props) => (
  <Icon {...props}>
    <svg viewBox="0 0 16 16">
      <path
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        d="M4.893.634A7.82 7.82 0 018 0a7.82 7.82 0 013.107.634c.988.423 1.84.99 2.554 1.705s1.283 1.565 1.705 2.554A7.82 7.82 0 0116 8a7.8 7.8 0 01-.634 3.098c-.417.988-.982 1.84-1.696 2.554s-1.565 1.283-2.554 1.705C10.128 15.786 9.09 16 8 16s-2.128-.21-3.116-.634-1.84-.99-2.554-1.705a8.25 8.25 0 01-1.696-2.554A7.82 7.82 0 010 8a7.82 7.82 0 01.634-3.107c.423-.988.99-1.84 1.705-2.554S3.905 1.057 4.893.634zm8.634 11.43c.887-1.22 1.33-2.574 1.33-4.062a6.67 6.67 0 00-.545-2.661c-.363-.845-.85-1.574-1.464-2.187s-1.342-1.1-2.187-1.464A6.67 6.67 0 008 1.143a6.67 6.67 0 00-2.661.545c-.845.363-1.574.85-2.187 1.464S2.05 4.494 1.688 5.34A6.67 6.67 0 001.143 8c0 1.488.443 2.842 1.33 4.063.393-1.946 1.304-2.92 2.732-2.92.78.762 1.71 1.143 2.795 1.143s2.015-.38 2.795-1.143c1.43 0 2.34.973 2.732 2.92zm-3.107-3.35a3.31 3.31 0 001.009-2.429 3.28 3.28 0 00-1.009-2.42A3.28 3.28 0 008 2.857a3.31 3.31 0 00-2.429 1.009c-.667.667-1 1.473-1 2.42s.333 1.756 1 2.43c.673.667 1.482 1 2.43 1s1.753-.333 2.42-1z"
      />
    </svg>
  </Icon>
);
