import { css } from '@emotion/react';
import { readableColor } from 'polished';
import { ThemeColorsKeys } from '../styles/tokens/colors';
import { colors } from '../styles/tokens';
/**
 * Returns a CSS string with background-color and color properties based on the provided color key.
 *
 * @param {ThemeColorsKeys} color - The color key to use for the background-color and color properties.
 * @returns {string|undefined} - A CSS string with background-color and color properties, or undefined if the provided color key is invalid.
 */
export const getBackgroundColor = (color: ThemeColorsKeys) => {
  if (!color || !colors[color]) {
    return;
  }

  return css`
    background-color: ${colors[color]};
    color: ${readableColor(
      colors[color],
      colors.neutral100,
      colors.neutralWhite,
      true
    )};
  `;
};
