import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React, { forwardRef } from 'react';
import { ThemeButtonKeys } from '../../styles/tokens/buttons';
import { fontWeight } from '../../styles/tokens/typography';
import { getButtonStyles, getResponsiveType } from '../../utils';
import { getOffsetSpace } from '../../utils/getOffsetSpace';
import { ArrowLeft, ArrowRight } from '../icon';
import { LinkTo } from '../linkTo/LinkTo';

export type ButtonType = HTMLButtonElement | HTMLAnchorElement;

export interface ButtonProps
  extends Partial<HTMLButtonElement>,
    Partial<
      Omit<HTMLAnchorElement, 'addEventListener' | 'removeEventListener'>
    > {
  variant?: ThemeButtonKeys;
  label?: string;
  url?: string;
  as?: string;
  a11yTitle?: string;
  selected?: boolean;
  arrowRight?: boolean;
  arrowLeft?: boolean;
  iconRight?: React.ReactNode;
  iconLeft?: React.ReactNode;
  onClick?: React.MouseEventHandler<ButtonType>;
  small?: boolean;
}

type StyledButtonProps = Pick<
  ButtonProps,
  'variant' | 'disabled' | 'selected' | 'small'
> & { iconOnly?: boolean };

export const StyledButton = styled.a<StyledButtonProps>`
  ${({
    theme: { space, media, borderRadii, colors },
    variant,
    selected,
    small,
    iconOnly,
  }) => {
    const spaceXxSmall = getOffsetSpace(space.xxSmall);
    const spaceXSmall = getOffsetSpace(space.xSmall);
    const spaceSmall = getOffsetSpace(space.xLarge);
    const spaceMedium = getOffsetSpace(space.medium);

    // Size

    let paddingSmall = `${spaceXxSmall} ${spaceXSmall}`;
    let paddingLarge = `${spaceXxSmall} ${spaceSmall}`;

    if (iconOnly) {
      paddingSmall = spaceXxSmall;
      paddingLarge = spaceXSmall;
    }

    if (small) {
      paddingLarge = paddingSmall;
    }

    if (small && iconOnly) {
      paddingSmall = '0';
      paddingLarge = '0';
    }

    return css`
      display: inline-flex;
      justify-content: center;
      cursor: pointer;
      align-items: center;
      gap: ${space.xxSmall};
      padding: ${paddingSmall};
      border: solid 2px;
      ${getResponsiveType('bodyMedium')}
      font-weight: ${fontWeight.bold};
      ${variant && getButtonStyles(variant, selected)};
      border-radius: clamp(${borderRadii.medium}, 5vw, ${borderRadii.large});
      min-height: ${iconOnly ? 'auto' : space.large};
      min-width: ${space.medium};
      text-decoration: none;

      // Icon only

      ${iconOnly &&
      css`
        padding: 0;
        border: none;

        &:hover {
          color: ${colors.brandOne100};
          border-color: ${colors.brandOne100};
          background-color: ${colors.neutralWhite};
        }
      `}

      // Disabled


      &[disabled] {
        cursor: default;
        opacity: 0.5;
        pointer-events: none;
      }

      // Large screens

      @media (min-width: ${media.medium}) {
        padding: ${paddingLarge};
        min-height: ${iconOnly ? 'auto' : space.large};
        min-width: ${iconOnly ? 'auto' : space.xLarge};
      }
    `;
  }};
`;

export const Button = forwardRef<
  ButtonType,
  React.PropsWithChildren<ButtonProps>
>(
  (
    {
      children,
      a11yTitle,
      onClick,
      disabled = false,
      variant = 'primary',
      url,
      as,
      target,
      label,
      selected = 'false',
      iconLeft,
      iconRight,
      arrowRight,
      arrowLeft,
      small,
      ...rest
    },
    ref
  ) => {
    // Handle click

    const handleClick = onClick;

    // Icons

    const IconLeft = iconLeft;
    const IconRight = iconRight;

    // Component as

    let component;

    if (as) {
      component = as;
    } else if (!url) {
      component = 'button';
    } else {
      component = LinkTo;
    }

    return (
      <StyledButton
        ref={ref}
        to={url}
        as={component}
        onClick={handleClick}
        aria-label={a11yTitle}
        variant={variant}
        disabled={disabled}
        selected={selected}
        aria-disabled={disabled}
        iconOnly={(arrowLeft || arrowRight || iconRight || iconLeft) && !label}
        small={small}
        target={target}
        {...rest}
      >
        {arrowLeft && <ArrowLeft />}
        {iconLeft && !arrowLeft ? IconLeft : ''}
        {children || label}
        {iconRight && !arrowRight ? IconRight : ''}
        {arrowRight && <ArrowRight />}
      </StyledButton>
    );
  }
);

Button.displayName = 'Button';

export default Button;
