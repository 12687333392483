import React, { FC } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

// Interface

interface ContainerProps {
  narrow?: boolean;
}

// Styles

const StyledContainer = styled.div<ContainerProps>`
  ${({ theme: { space, media }, narrow }) => css`
    margin: 0 auto;
    padding: 0 ${space.small};
    max-width: 1040px;

    // Narrow width

    ${narrow &&
    css`
      max-width: 736px;
    `}

    @media (min-width: ${media.medium}) {
      padding: 0 ${space.medium};
    }

    @media (min-width: ${media.xLarge}) {
      padding: 0;
    }
  `};
`;

// JSX

export const Container: FC<React.PropsWithChildren<ContainerProps>> = ({
  children,
  narrow,
  ...rest
}) => (
  <StyledContainer narrow={narrow} {...rest}>
    {children}
  </StyledContainer>
);

export default Container;
